import styled from '@emotion/styled'

interface Props {
  size: number
}

export const Spacer = styled.div<Props>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`
