import { Color, Mesh } from 'three'
import React, { useMemo, useRef } from 'react'
import { RootState, useFrame } from '@react-three/fiber'

/**
 * TypeScript is being annoying with these so I'm just going to supress the errors here.
 *
 * @ts-expect-error */
import { noise } from './perlin'
// @ts-expect-error
import vertexShader from '!!raw-loader!../shaders/warping-plane.vs.glsl'
// @ts-expect-error
import fragmentShader from '!!raw-loader!../shaders/warping-plane.fs.glsl'

const planeBufferParameters = {
  width: 1024,
  height: 1024,
  widthSegments: 32,
  heightSegments: 32,
}

export const WarpingPlane = () => {
  const mesh = useRef<Mesh>()
  const planeBufferArgs = Object.values(planeBufferParameters)
  const applyStaticNoiseDisplacement = ({ geometry }) => {
    noise.seed(Math.random())
    let position = geometry.getAttribute('position')
    const hVerts = geometry.parameters.heightSegments + 1
    const wVerts = geometry.parameters.widthSegments + 1
    for (let j = 0; j < hVerts; j++) {
      for (let i = 0; i < wVerts; i++) {
        const ex = 1.1
        position.array[3 * (j * wVerts + i) + 2] =
          (noise.simplex2(i / 100, j / 100) +
            noise.simplex2((i + 200) / 50, j / 50) * Math.pow(ex, 1) +
            noise.simplex2((i + 400) / 25, j / 25) * Math.pow(ex, 2) +
            noise.simplex2((i + 600) / 12.5, j / 12.5) * Math.pow(ex, 3) +
            +(noise.simplex2((i + 800) / 6.25, j / 6.25) * Math.pow(ex, 4))) /
          2
      }
    }

    position.needsUpdate = true
  }

  const uniforms = useMemo(
    () => ({
      u_time: {
        value: 0.0,
      },
      u_colorA: { value: new Color('#FFE486') },
      u_colorB: { value: new Color('#FEB3D9') },
    }),
    []
  )

  useFrame((state: RootState, delta) => {
    mesh.current.material.uniforms.u_time.value += delta * 1.1
  })

  return (
    <mesh
      ref={mesh}
      position={[-0.5, 0, 0]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={0.005}
      // onUpdate={applyStaticNoiseDisplacement}
    >
      <planeBufferGeometry attach="geometry" args={planeBufferArgs} />
      <rawShaderMaterial
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniforms={uniforms}
        wireframe={true}
      />
    </mesh>
  )
}
