/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Heading, InternalLink } from '../ui'
import { MobileNav } from './mobile-nav'
import { BREAKPOINTS, LIGHT_COLORS, navItems } from '../constants'

type HeaderProps = {
  isHome: boolean
  isResume: boolean
  displayTitle: boolean
}

export const Header = ({
  isHome,
  isResume,
  displayTitle = true,
}: HeaderProps) => {
  let title = isResume ? 'prayash thapa' : 'prayash.io'
  return (
    <div
      className="header"
      css={css`
        align-items: center;
        display: flex;
        justify-content: ${isHome ? 'center' : 'space-between'};
        padding: 1em 0;
        @media print {
          padding-bottom: 12px;
        }
      `}
    >
      <HeadingLink to="/" noUnderline>
        {displayTitle && (
          <Heading
            type="medium-title"
            css={css`
              color: hsl(330deg 100% 55%);
              font-size: 24px;
              @media print {
                color: var(--color-primary);
              }
            `}
          >
            {title}
          </Heading>
        )}
      </HeadingLink>

      <RightMobile>{!isHome && <MobileNav />}</RightMobile>

      <RightDesktop>
        {!isHome && (
          <Nav>
            {navItems.map(({ label, href }) => (
              <InternalLink
                to={href}
                key={label}
                activeStyle={{ color: LIGHT_COLORS.link }}
                noUnderline
                isNavigationItem={true}
                spaced
              >
                {label}
              </InternalLink>
            ))}
          </Nav>
        )}
      </RightDesktop>
    </div>
  )
}

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
`

const RightMobile = styled.div`
  @media ${BREAKPOINTS.desktop} {
    display: none;
  }
`

const RightDesktop = styled.div`
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`

const HeadingLink = styled(InternalLink)`
  border-bottom: 0px !important;
  color: var(--color-primary);
  display: flex;
  font-size: 24px;
  letter-spacing: -1px;
  padding: 0;
  text-decoration: none;
`
