import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps extends PropsWithChildren<{}> {
  id: string
}

export const Portal: React.FC<PortalProps> = ({ id, children }) => {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return ReactDOM.createPortal(children, document.querySelector(`#${id}`))
}
