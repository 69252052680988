/** @jsx jsx */

import styled from '@emotion/styled'
import { BREAKPOINTS, CONTENT_MAX_WIDTH } from '../constants'

export const BlogOrderedList = styled.ol`
  margin: 32px auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  font-size: 18px;

  @media ${BREAKPOINTS.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
`
