/** @jsx jsx */

import styled from '@emotion/styled'
import { BREAKPOINTS, CONTENT_MAX_WIDTH } from '../constants'

interface ParagraphProps {
  small: boolean
}

export const Paragraph = styled.p<ParagraphProps>`
  color: var(--color-primary);
  font-weight: 400;
  font-size: ${(p) => (p.small ? '16px' : '18px')};
  margin-bottom: 32px;
  max-width: ${CONTENT_MAX_WIDTH}px;
`

export const BlogParagraph = styled.p<ParagraphProps>`
  color: var(--color-primary);
  font-weight: 400;
  font-size: ${(p) => (p.small ? '16px' : '18px')};
  margin: 32px auto;
  max-width: ${CONTENT_MAX_WIDTH}px;

  @media ${BREAKPOINTS.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
`
