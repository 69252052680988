import React from 'react'
import { MDXProvider } from '@mdx-js/tag'
import { preToCodeBlock } from 'mdx-utils'

// components is its own object outside of render so that the references to
// components are stable
const components = {
  pre: (preProps) => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      // return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
}
export const wrapRootElement = ({ element }) => {
  logCustomMessageToConsole()
  return <MDXProvider components={components}>{element}</MDXProvider>
}

function logCustomMessageToConsole() {
  console.log(
    "%chi there 👋 \nwelcome to my little corner of the web!\nfancy seeing you around these parts of the browser. i hope you're having fun poking around.\ni treat this website as a product – and i'm constantly tinkering and redesigning bits and pieces to explore new ideas.\nfeel free to explore and reach out if you have any questions.",
    'font-weight: bold; font-size: 14px; line-height: 1.65; color: #fafafa; background-image: linear-gradient(to top left, pink, navy)'
  )
}
