/** @jsx jsx */

import styled from '@emotion/styled'
import { jsx } from '@emotion/react'
import { BREAKPOINTS, CONTENT_MAX_WIDTH } from '../constants'

export const Heading = ({ type = 'medium-title', ...delegated }) => {
  const getHeadingForType = (type: string) => {
    const HEADING_MAP = {
      'section-title': SectionTitle,
      'small-title': SmallTitle,
      'medium-title': MediumTitle,
      'large-title': LargeTitle,
      'major-heading': MajorHeading,
      'normal-heading': NormalHeading,
      'minor-heading': MinorHeading,
      'blog-heading': BlogHeading,
      'blog-small-heading': BlogSmallHeading,
    }

    if (!HEADING_MAP[type]) {
      throw new Error('Unrecognized Heading type: ' + type)
    }

    return HEADING_MAP[type]
  }

  let Component = getHeadingForType(type)

  return <Component {...delegated} />
}

const SmallTitle = styled.h3`
  color: var(--color-primary);
  font-weight: 400;
  font-size: 22px;
`

const SectionTitle = styled.h4`
  color: var(--color-secondary);
  font-family: var(--font-family-heavy);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
`

const MediumTitle = styled.h1`
  font-family: var(--font-family-heavy);
  font-size: 28px;
  color: var(--color-gray-1000);
  line-height: 1.2;
`
const LargeTitle = styled.h1`
  color: var(--color-gray-1000);
  font-family: var(--font-family-heavy);
  font-size: 38px;
  margin-bottom: 16px;
`

const MajorHeading = styled.h2`
  color: var(--color-primary);
  font-family: var(--font-family-heavy);
  font-size: 32px;
  margin-top: 48px;
  margin-bottom: 16px;
`

const NormalHeading = styled.h3`
  color: var(--color-secondary);
  font-family: var(--font-family-heavy);
  font-size: 25px;
  margin-top: 32px;
  margin-bottom: 12px;
`

const MinorHeading = styled.h4`
  font-size: 20px;
  color: var(--color-gray-900);
  margin-top: 24px;
  margin-bottom: 12px;
`

const BlogHeading = styled.h1`
  color: var(--color-gray-1000);
  font-family: var(--font-family-heavy);
  font-size: 32px;
  margin: 40px auto 16px;
  max-width: ${CONTENT_MAX_WIDTH}px;
`

const BlogSmallHeading = styled.h3`
  color: var(--color-primary);
  font-family: var(--font-family-heavy);
  font-size: 24px;
  margin: 0 auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  @media ${BREAKPOINTS.mobile} {
    padding-left: 24px;
    padding-right: 24px;
  }
`
