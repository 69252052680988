/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useSpring, useTrail, animated } from 'react-spring'
import { DefaultButton, Portal, Spacer } from '../ui'
import { BREAKPOINTS, navItems } from '../constants'
import { Link } from 'gatsby'

export const MobileNav = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const trail = useTrail(navItems.length, {
    transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
    config: {
      tension: 700,
      friction: isOpen ? 60 : 20,
      clamp: true,
    },
  })

  return (
    <Portal id="mobile-nav-portal">
      <Trigger
        onClick={() => setIsOpen(!isOpen)}
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
        style={{ top: 32 }}
      >
        <MenuIcon isOpen={isOpen} size={32} />
      </Trigger>

      <ModalWrapper style={{ pointerEvents: isOpen ? 'auto' : 'none' }}>
        <Backdrop
          style={{
            opacity: isOpen ? 1 : 0,
            touchAction: isOpen ? 'none' : 'auto',
          }}
          onClick={() => setIsOpen(false)}
          aria-hidden={true}
          tabIndex={-1}
        />

        <Nav aria-label="mobile navigation">
          <Top>
            {trail.map((style, index) => {
              const { label, href } = navItems[index]

              return (
                <animated.div style={style} key={index}>
                  <NavLink
                    onClick={() => setIsOpen(false)}
                    activeClassName="active"
                    to={href}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    {label}{' '}
                  </NavLink>
                </animated.div>
              )
            })}
          </Top>
          <Bottom
            style={{
              opacity: isOpen ? 1 : 0,
              transition: `opacity 250ms ${isOpen ? 500 : 0}ms`,
            }}
          >
            {/* <div>Test</div>
            <Spacer size={30} />
            <div>Hello</div> */}
          </Bottom>
        </Nav>
      </ModalWrapper>
    </Portal>
  )
}

const Trigger = styled(DefaultButton)`
  position: fixed;
  z-index: 10001;
  right: 32px;
  width: 40px;
  height: 40px;

  @media ${BREAKPOINTS.desktop} {
    display: none;
  }

  @media print {
    display: none;
  }
`

const Backdrop = styled(DefaultButton)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: var(--color-blurred-background);
  backdrop-filter: blur(24px);

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 250ms;
  }
`

const Nav = styled(animated.nav)`
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  height: 75%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NavLink = styled(Link)`
  display: block;
  position: relative;
  padding: 16px;
  padding-left: 32px;
  text-decoration: none;
  color: var(--color-text);
  font-weight: var(--font-weight-medium);
  font-size: 28px;

  &.active {
    color: var(--color-primary);
  }
`

const Top = styled.div``
const Bottom = styled.div`
  display: flex;
  padding-left: 30px;
`

const MenuIcon = ({ isOpen, size }) => {
  const svgProps = useSpring({
    transform: `rotate(${isOpen ? -45 : 0}deg)`,
    config: {
      tension: 150,
      friction: 25,
    },
  })

  const line1Props = useSpring({
    x1: isOpen ? size / 2 : size * 0.15,
    y1: isOpen ? 0 : size * 0.4,
    x2: isOpen ? size / 2 : size * 0.85,
    y2: isOpen ? size : size * 0.4,
    config: {
      tension: 150,
      friction: 22,
    },
  })
  const line2Props = useSpring({
    x1: isOpen ? 0 : size * 0.85,
    y1: isOpen ? size / 2 : size * 0.6,
    x2: isOpen ? size : size * 0.15,
    y2: isOpen ? size / 2 : size * 0.6,
    config: {
      tension: 110,
      friction: 22,
    },
  })

  return (
    <Svg
      style={{
        width: size,
        height: size,
        ...svgProps,
      }}
    >
      <animated.line {...line1Props} stroke="black" strokeWidth="2" />
      <animated.line {...line2Props} stroke="black" strokeWidth="2" />
    </Svg>
  )
}

const Svg = styled(animated.svg)`
  display: block;
  overflow: visible;
  transform-origin: center center;
`

const ModalWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
`
