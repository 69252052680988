/**
 * Navigation
 */
export enum TopLevelPage {
  home = 0,
  journal,
  software,
  music,
  feed,
  about,
}

const topLevelPages = Object.values(TopLevelPage).filter(
  (value) => typeof value === 'string'
) as string[]

function slugFor(page: string): string {
  return `/${page === TopLevelPage[TopLevelPage.home] ? '' : page}`
}

export const navItems = topLevelPages.map((page) => ({
  label: page,
  href: slugFor(page),
}))

/**
 * WebGL Parameters.
 */
export const params: {
  CANVAS_WIDTH: string
  CANVAS_HEIGHT: string
  CLEAR_COLOR: string
  CANVAS_ENTRY_DELAY_IN_MS: number
} = {
  CANVAS_WIDTH: `100vw`,
  CANVAS_HEIGHT: `100vh`,
  CLEAR_COLOR: `#fafafa`,
  CANVAS_ENTRY_DELAY_IN_MS: 420,
}

/**
 * CSS Variables & Breakpoints.
 */
export const LIGHT_COLORS: {
  text: string
  link: string
  background: string
  blurredBackground: string
  primary: string
  secondary: string
  tertiary: string
  decorative: string
  muted: string
  error: string
  errorBackground: string
  success: string
  successBackground: string
  alert: string
  alertBackground: string
  venn: string[]
  gray: {
    '100': string
    '200': string
    '300': string
    '400': string
    '500': string
    '600': string
    '700': string
    '900': string
    '1000': string
  }
  subtleBackground?: string
  subtleFloating?: string
  homepageLight?: string
  homepageDark?: string
  homepageBg?: string
  info?: string
} = {
  text: 'hsl(0, 0%, 16%)',
  link: 'hsl(234deg 75% 57%)',
  background: '#f4f4f4',
  blurredBackground: 'hsla(0deg, 0%, 100%, 0.85)',
  primary: 'hsl(0, 0%, 16%)',
  secondary: 'hsl(339, 54%, 54%)',
  tertiary: 'hsl(255deg, 85%, 30%)',
  decorative: 'hsl(200deg, 75%, 65%)',
  muted: 'hsl(210deg, 55%, 92%)',
  error: 'hsl(340deg, 95%, 50%)',
  errorBackground: 'hsla(340deg, 95%, 43%, 0.1)',
  success: 'hsl(160deg, 100%, 40%)',
  successBackground: 'hsla(160deg, 100%, 40%, 0.1)',
  alert: 'hsl(37deg, 100%, 50%)',
  alertBackground: 'hsla(52deg, 100%, 50%, 0.25)',
  venn: ['hsl(190deg, 100%, 65%)', 'hsl(340deg, 100%, 65%)'],
  gray: {
    '100': 'hsl(225deg, 25%, 95%)',
    '200': 'hsl(225deg, 16%, 90%)',
    '300': 'hsl(225deg, 8%, 80%)',
    '400': 'hsl(225deg, 8%, 70%)',
    '500': 'hsl(225deg, 7%, 60%)',
    '600': 'hsl(225deg, 15%, 50%)',
    '700': 'hsl(225deg, 12%, 40%)',
    '900': 'hsl(225deg, 25%, 20%)',
    '1000': 'hsl(225deg, 15%, 15%)',
  },
}

LIGHT_COLORS.subtleBackground = LIGHT_COLORS.gray[100]
LIGHT_COLORS.subtleFloating = LIGHT_COLORS.background
LIGHT_COLORS.homepageLight = 'hsl(204deg, 67%, 85%)'
LIGHT_COLORS.homepageDark = 'hsl(202deg, 71%, 90%)'
LIGHT_COLORS.homepageBg = LIGHT_COLORS.homepageLight
LIGHT_COLORS.info = LIGHT_COLORS.primary

const BREAKPOINT_SIZES: {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
} = {
  xs: 320,
  sm: 563,
  md: 768,
  lg: 1024,
  xl: 1440,
}

export const BREAKPOINTS: {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xsAndSmaller: string
  smAndSmaller: string
  mdAndSmaller: string
  lgAndSmaller: string
  xlAndSmaller: string
  xsAndLarger: string
  smAndLarger: string
  mdAndLarger: string
  lgAndLarger: string
  xlAndLarger: string
  mobile: string
  desktop: string
} = {
  xs: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  sm: `(min-width: ${BREAKPOINT_SIZES.xs}px and max-width: ${BREAKPOINT_SIZES.sm}px)`,
  md: `(min-width: ${BREAKPOINT_SIZES.sm}px and max-width: ${BREAKPOINT_SIZES.md}px)`,
  lg: `(min-width: ${BREAKPOINT_SIZES.md}px and max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xl: `(min-width: ${BREAKPOINT_SIZES.lg}px and max-width: ${BREAKPOINT_SIZES.xl}px)`,
  xsAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  smAndSmaller: `(max-width: ${BREAKPOINT_SIZES.sm}px)`,
  mdAndSmaller: `(max-width: ${BREAKPOINT_SIZES.md}px)`,
  lgAndSmaller: `(max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xlAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xl}px)`,
  xsAndLarger: `(min-width: ${BREAKPOINT_SIZES.xs + 1}px)`,
  smAndLarger: `(min-width: ${BREAKPOINT_SIZES.sm + 1}px)`,
  mdAndLarger: `(min-width: ${BREAKPOINT_SIZES.md + 1}px)`,
  lgAndLarger: `(min-width: ${BREAKPOINT_SIZES.lg + 1}px)`,
  xlAndLarger: `(min-width: ${BREAKPOINT_SIZES.xl + 1}px)`,
  mobile: `(max-width: ${BREAKPOINT_SIZES.md}px)`,
  desktop: `(min-width: ${BREAKPOINT_SIZES.md + 1}px)`,
}

export const READING_WIDTH: number = 850
export const EXTRA_WIDE_WIDTH: number = 1024

const mobileRegex: RegExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i

const userAgent: string =
  typeof window !== 'undefined' ? window.navigator.userAgent : 'node'

export const IS_MOBILE_USER_AGENT: boolean = mobileRegex.test(userAgent)

export const UNIT: number = 8

export const Z_INDICES: {
  hero: number
  mainContent: number
  header: number
} = {
  hero: 1,
  mainContent: 10,
  header: 100,
}

export const PREFERS_DARK_KEY: string = 'prefers-dark'

export const PREFERS_DARK_CSS_PROP: string = `--${PREFERS_DARK_KEY}`
export const COLOR_SWAP_TRANSITION_DURATION: number = 350

export const THEME: {
  unit: number
  breakpoints: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xsAndSmaller: string
    smAndSmaller: string
    mdAndSmaller: string
    lgAndSmaller: string
    xlAndSmaller: string
    xsAndLarger: string
    smAndLarger: string
    mdAndLarger: string
    lgAndLarger: string
    xlAndLarger: string
    mobile: string
    desktop: string
  }
} = {
  unit: UNIT,
  breakpoints: BREAKPOINTS,
}

export const HEADER_HEIGHT: number = 60
export const CONTENT_MAX_WIDTH: number = 675
export const CONTENT_SIDEBAR_WIDTH: number = 350

export const TIGHT_SPRING: {
  tension: number
  friction: number
} = {
  tension: 450,
  friction: 25,
}
