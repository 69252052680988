import { graphql, useStaticQuery } from 'gatsby'

type SiteMetadataProps = {
  site: {
    siteMetadata: {
      title: string
      shortTitle: string
      author: string
      description: string
      image: string
      url: string
      siteUrl: string
      social: {
        bandcamp: string
        email: string
        github: string
        instagram: string
        linkedin: string
        soundcloud: string
        spoopify: string
        tiktok: string
        twitter: string
        youtube: string
        pallusYouTube: string
      }
    }
  }
}

/**
 * Use this for general static strings.
 * @returns {*} Metadata for site-wide consumption.
 */
export const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetadataProps>(graphql`
    query {
      site {
        siteMetadata {
          title
          shortTitle
          author
          description
          image
          url
          siteUrl
          social {
            appleMusic
            bandcamp
            email
            github
            instagram
            linkedin
            soundcloud
            spoopify
            tiktok
            twitter
            youtube
            pallusYouTube
          }
        }
      }
    }
  `)

  return data.site.siteMetadata
}
