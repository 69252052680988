/** @jsx jsx */

import { PropsWithChildren } from 'react'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { TopLevelPage } from '../constants'
import { Seo } from '../components/seo'

interface PageRootProps extends PropsWithChildren<{}> {
  page?: TopLevelPage
  title?: string
  featuredImage?: any
}

const RootDiv = styled.div``

export const PageRoot: React.FC<PageRootProps> = ({
  page = null,
  title = null,
  featuredImage = null,
  children,
}) => {
  const isHome = title == null && page == null

  return (
    <RootDiv className="page-root">
      <Seo
        isHome={isHome}
        pageName={title ?? TopLevelPage[page]}
        featuredImage={featuredImage}
      />
      {children}
    </RootDiv>
  )
}
