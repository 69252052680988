import React, { useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { WarpingPlane } from './warping-plane'
import { params } from '../constants'
import { PerformanceMonitor } from '@react-three/drei'

/**
 * Core WebGL scene which should take up the entire viewport.
 */
export const GLCanvas = () => {
  const [showCanvas, setShowCanvas] = useState(false)
  const [dpr, setDpr] = useState(1.5)

  useEffect(() => {
    setTimeout(() => {
      setShowCanvas(true)
    }, params.CANVAS_ENTRY_DELAY_IN_MS)
  }, [])

  return (
    <div
      className="canvas-wrapper"
      style={{ width: params.CANVAS_WIDTH, height: params.CANVAS_HEIGHT }}
    >
      <Canvas
        className={`gl-canvas ${showCanvas ? 'display' : 'hide'}`}
        shadows
        dpr={dpr}
        camera={{ position: [-2, 2, 6], fov: 50, near: 1, far: 10 }}
        onCreated={(state) => state.gl.setClearColor(params.CLEAR_COLOR, 1.0)}
      >
        <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        />

        {false && <color attach="background" args={['#202020']} />}
        {false && <fog attach="fog" args={['#202020', 5, 20]} />}
        {false && <ambientLight intensity={0.015} />}

        <WarpingPlane />
      </Canvas>
    </div>
  )
}

/**
 * Lazy loading?!
 * import { Suspense, lazy } from 'react'
import { useInView } from 'react-intersection-observer'

const Canvas = lazy(() => import('./Canvas'))

function App() {
  const { ref, inView } = useInView()
  return (
    <div ref={ref} className="canvasContainer">
      <Suspense fallback={null}>
        {inView && (
          <Canvas>
            ...
 */
