/** @jsx jsx */

import styled from '@emotion/styled'

export const Img = styled.img`
  width: 100%;
`

export const BlogImage = styled.img`
  width: 100%;
`
