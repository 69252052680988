import styled from '@emotion/styled'

export const DefaultButton = styled.button`
  display: ${(props) => props.display || 'block'};
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;

  &:focus {
    outline: 2px auto var(--color-primary);
    outline-offset: 2px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`
export const SocialButton = styled.a`
  width: 42px;
  height: 42px;
  border: 1px solid gray;
  border-radius: 50%;
  margin-top: 0;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  transition: all 0.2s;
  position: relative;
`
