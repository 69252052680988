/** @jsx jsx */

import { css, jsx, Global } from '@emotion/react'
import { LIGHT_COLORS, CONTENT_MAX_WIDTH } from '../constants'

let cssReset = `
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }

  blockquote, q {
    quotes: none;
  }
  
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    text-rendering: optimizeLegibility;
  }
`

const globalStyles = css`
  /* CSS RESET */
  ${cssReset}

  /* GLOBAL STYLES */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    line-height: 1.6;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Inter-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Inter-Italic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/Inter-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/Inter-SemiBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Inter-Bold.woff2') format('woff2');
  }

  // @font-face {
  //   font-family: 'Neuzeit-Book';
  //   src: url('/fonts/neuzeit.woff2') format('woff2'),
  //     url('/fonts/neuzeit.woff') format('woff'),
  //     url('/fonts/neuzeit.ttf') format('ttf');
  // }

  // @font-face {
  //   font-family: 'Neuzeit-Heavy';
  //   src: url('/fonts/neuzeit-heavy.woff') format('woff'),
  //     url('/fonts/neuzeit-heavy.ttf') format('ttf');
  // }

  *: {
    box-sizing: inherit;
  }

  body {
    background: var(--color-background);
    color: #282828;
    font-family: var(--font-family);
  }

  body,
  input,
  button,
  select,
  option {
    font-family: var(--font-family);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: var(--font-weight-bold);
  }

  a {
    text-decoration: none;
  }

  code {
    font-size: 12px;
  }

  /* Scrollbar and selection styles */
  ::selection {
    background-color: ${LIGHT_COLORS.primary};
    color: white;
  }

  @media (orientation: landscape) {
    ::-webkit-scrollbar {
      width: 12px;
      background-color: var(--color-gray-100);
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: var(--color-gray-700);
      border: 2px solid var(--color-gray-100);
    }
  }

  /* CSS Variables */
  :root {
    /*  Colors are injected in 'gatsby-ssr.js'  */
    --font-weight-bold: 500;
    --font-weight-medium: 400;
    --font-weight-light: 300;

    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, Helvetica Neue,
      sans-serif;
    --font-family-semibold: 'Inter-SemiBold', -apple-system, BlinkMacSystemFont,
      Helvetica Neue, sans-serif;
    --font-family-heavy: 'Inter-Bold', -apple-system, BlinkMacSystemFont,
      Helvetica Neue, sans-serif;
    --font-family-mono: 'League Mono', 'Fira Mono', monospace;

    --color-background: ${LIGHT_COLORS.background};
    --color-primary: ${LIGHT_COLORS.primary};
    --color-link: ${LIGHT_COLORS.link};
    --color-secondary: ${LIGHT_COLORS.secondary};
    --color-tertiary: ${LIGHT_COLORS.tertiary};
    --color-decorative: ${LIGHT_COLORS.decorative};

    --tw-backdrop-blur: blur(8px);
    --tw-backdrop-saturate: saturate(1.5);

    --read-grey: #929292;
  }

  .gl-canvas {
    opacity: 0;
    transition: opacity 420ms ease-in;
  }

  .display {
    opacity: 1;
  }

  .list {
    position: relative;
    font-family: var(--font-family);
    width: 100%;
    height: 100%;
  }

  .list > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 15px;
  }

  .list > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  }

  hr {
    display: block;
    margin: 32px auto;
    max-width: ${CONTENT_MAX_WIDTH}px;
  }

  a.gatsby-resp-image-link {
    border-bottom: none !important;
    box-shadow: none !important;
  }

  img.gatsby-resp-image-image {
    box-shadow: none !important;
  }

  div.blog-post-body p > strong {
    font-weight: 600;
  }

  .canvas-wrapper {
    z-index: -1;
    animation: fade-in 0.5s ease 0.3s forwards;
    animation-delay: 0s;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex.fill {
    height: 100%;
  }

  .flex.center {
    justify-content: center;
  }

  @keyframes op {
    from {
      opacity: 0.85;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes tr {
    from {
      transform: scaleX(1) scaleY(1);
    }

    to {
      transform: scaleX(2.5) scaleY(2.5);
    }
  }
`

export const GlobalStyles = () => {
  return <Global styles={globalStyles} />
}
